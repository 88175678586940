<!-- Author:孔德昊 -->


<template>
  <div class="main">
    <keep-alive>
      <router-view
        v-if="$route.meta.keepalive"
        :customerlist="customerlist"
        :contactslist="contactslist"
        :CommonObjectList="CommonObjectList"
        :AllObjectList="AllObjectList"
        ref="step1"
      />
    </keep-alive>
      <router-view
        v-if="!$route.meta.keepalive"
        :customerlist="customerlist"
        :contactslist="contactslist"
        :CommonObjectList="CommonObjectList"
        :AllObjectList="AllObjectList"
        ref="step1"

      />
  </div>
</template>
<script>
import { getObjectList, importnum } from "./api";
export default {
  data() {
    return {
      CommonObjectList: [],
      AllObjectList: [],
      customerlist: [
        {
          value: "名称",
          label: this.$i18n.t("component.newrecords.label.name"), //名称
        },
        {
          value: "ID",
          label: "ID",
        },
      ],
      contactslist: [
        {
          value: "名称",
          label: this.$i18n.t("component.newrecords.label.name"), //名称
        },
        {
          value: "手机",
          label: this.$i18n.t("label.mobile"), //手机
        },
        {
          value: "邮箱",
          label: this.$i18n.t("label.emailsync.form.email"), //邮箱
        },
        {
          value: "ID",
          label: "ID",
        },
      ],
    };
  },
  created() {
    this.normallist();
  },
  mounted() {
    this.importnum();
    this.$store.state.curdata = "";
    this.$store.state.checked1 = "false";
    this.$store.state.checked2 = "false";
    this.$store.state.checked3 = "false";
    this.$store.state.checked4 = "false";
    this.$store.state.defaultselect = "insert";
  },
  methods: {
    //获取导入的常用对象和全部对象
    async normallist() {
      let res = await getObjectList();
      if (res.result) {
        //常用对象列表
        this.CommonObjectList = res.data.CommonObjectList;
        this.AllObjectList = res.data.AllObjectList;
        // 全部对象列表
        if(res.data.ManageData === true){
          this.$refs.step1.AllObjectListshow = true;
        }else{
          this.$refs.step1.AllObjectListshow = false
        }
      }
    },

    // 获取导入数量
    async importnum() {
      let res = await importnum();
      this.numlimit = res.data;
      this.$store.commit("getnumlimit", this.numlimit);
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  height: 98%;
}
</style>

