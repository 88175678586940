import axios from "@/config/httpConfig";

//个人设置--导入

// 获取列的导入权限
export function getObject(data) {
  return axios.post("/importFile/getObject", data);
}



// 获取数据导入权限
export function getImportPermission(accessToken) {
  return axios.post("importData/getImportPermission", accessToken);
}



// 获取常用和全部导入对象
export function getObjectList(accessToken) {
  return axios.post("importData/getObjectList", accessToken);
}


//获取导入对象字段列表
export function importFieldList(id) {
  return axios.post("/importData/getFieldList", id);
}

//获取数据导入条数限制
export function importnum(accessToken) {
  return axios.post("/importData/getImportNum", accessToken);
}


//查询导入失败的文件
export function getMyDownPage(params) {
  return axios.post("/importFile/getMyDownPage", params);
}

//下载失败的文件
export function downloadFailFile(params) {
  return axios.get(`/importFile/downloadFailFile?fid=${params.fid}&auth=${params.auth}&oid=${params.oid}&accessToken=${params.accessToken}`);
}


//上传文件
export function uploadFile(data) {
  return axios.post("/importData/uploadFile", data)
}
